<template>
  <div>
    <div class="card p-1">
      <div class="row justify-content-between">
        <div class="h2 ml-1" style="color: #558cef">ประวัติการใช้งาน</div>
        <div class="mr-1"></div>
      </div>
    </div>
    <div class="card p-1">
      <!-- search input -->
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Search</label>
            <b-form-input v-model="searchTerm" placeholder="Search" type="text" class="d-inline-block" />
          </div>
        </b-form-group>
      </div>
      <!-- table -->
      <b-overlay :show="show" rounded="sm">
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
          :line-numbers="true"
        >
          <template slot="table-row" slot-scope="props">
            <!-- Column: Name -->
            <span v-if="props.column.field === 'fullName'" class="text-nowrap">
              <b-avatar :src="props.row.avatar" class="mx-1" />
              <span class="text-nowrap">{{ props.row.fullName }}</span>
            </span>

            <!-- Column: Status -->
            <span v-else-if="props.column.field === 'status'">
              <b-badge :variant="statusVariant(props.row.status)">
                {{ props.row.status }}
              </b-badge>
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <span>
                <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item>
                    <feather-icon icon="Edit2Icon" class="mr-50" />
                    <span>Edit</span>
                  </b-dropdown-item>
                  <b-dropdown-item>
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    <span>Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Showing 1 to </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['5', '10', '15', '25', '50', '100']"
                  class="mx-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value }, handleChangePage(value))
                  "
                />
                <span class="text-nowrap"> of {{ total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="handlePagechange($event)"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-overlay>
    </div>
    <!-- <div>ssssssss</div>
    <b-form-datepicker
      placeholder="วันที่รับ"
      id="example-datepicker"
      :date-format-options="{
        year: 'numeric',
        month: '2-digit',
        day: 'numeric',
      }"
      locale="th-TH"
      v-model="admitdate"
      :config="options"
      @input="TTH"
      @context="onContext"
    /> -->
    <!-- <DatePicker locale="th-TH" v-model="date" @input="data" /> -->
  </div>
</template>

<script>
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue';
import { VueGoodTable } from 'vue-good-table';
import axios from 'axios';
import API from '@/views/connectDB/condb.js';
import { DatePicker } from 'v-calendar';

// import os from "os";

// console.log(os.networkInterfaces());

// const networkInterfaces = Object.values(os.networkInterfaces())
//   .reduce((r, a) => {
//     r = r.concat(a);
//     return r;
//   }, [])
//   .filter(({ family, address }) => {
//     return family.toLowerCase().indexOf("v4") >= 0 && address !== "127.0.0.1";
//   })
//   .map(({ address }) => address);
// const ipAddresses = networkInterfaces.join(", ");
// console.log(ipAddresses);
export default {
  components: {
    VueGoodTable,
    StatisticCardVertical,
    DatePicker,
  },
  data() {
    const date = new Date();
    return {
      show: false,
      date: new Date('2565'),
      admitdate: new Date(date.getFullYear() + 543, date.getMonth(), date.getDate()),
      options: {
        format: 'DD/MM/YYYY',
        useCurrent: false,
      },
      value: null,
      pageLength: 100,
      currentPage: 1,
      dir: false,
      columns: [
        {
          label: 'วันที่',
          field: 'createdAt',
        },
        {
          label: 'ผู้ใช้',
          field: 'username',
        },
        // {
        //   label: "กลุ่มงาน",
        //   field: "กลุ่มงาน",
        // },
        // {
        //   label: "ฝ่ายงาน",
        //   field: "ฝ่ายงาน",
        // },
        {
          label: 'รายละเอียดงาน',
          field: 'event_name',
        },
        // {
        //   label: "รายละเอียด",
        //   field: "รายละเอียด",
        // },
        {
          label: 'IP',
          field: 'ipaddress',
        },
        // {
        //   label: "เวลา",
        //   field: "เวลา",
        // },

        // {
        //   label: "Action",
        //   field: "action",
        // },
      ],
      rows: [],
      searchTerm: '',
      total: '',
      status: '',
    };
  },
  computed: {
    example: {
      cache: false,
      get: function () {
        return Date.now() + this.msg;
      },
    },
  },
  async created() {
    const { username } = await JSON.parse(localStorage.getItem('username_type'));
    this.username = username;
    this.viewHistoryLogs();
    this.filterTaskByUser();
  },
  methods: {
    async filterTaskByUser() {
      // console.log(this.username);
      const { access_token } = await this.access_token();
      const url = `${API}filterTaskByUser?username=${this.username}&event_name=เบิกวัสดุ`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      // console.log(res.data.message.data);
    },
    data(value) {
      // console.log(value);
    },
    handlePagechange(event) {
      this.currentPage = event;
      this.viewHistoryLogs();
    },
    handleChangePage(event) {
      this.pageLength = event;
      this.viewHistoryLogs();
    },
    async viewHistoryLogs() {
      this.show = true
      const { access_token } = await this.access_token();
      const url = `${API}viewHistoryLogs?_page=${this.currentPage}&_limit=${this.pageLength}&_sort=-1`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      // console.log(res.data.message.data);
      this.rows = res.data.message.data;
      this.total = res.data.message.total;
      this.show = false
    },
    async access_token() {
      //get access_token
      return (
        await axios.post(`${API}permit`, '', {
          headers: {
            Authorization:
              localStorage.getItem('storedData') && JSON.parse(localStorage.getItem('storedData')),
          },
        })
      ).data.message;
    },
    TTH(i) {
      // console.log(i);
      // const aa = new Date(i)
      // const date = new Date(aa.getFullYear() + 543, aa.getMonth(), aa.getDate())
      // console.log(date)
    },
    onContext(ymd) {
      // console.log(ymd);
      // this.context = ctx;
    },
  },
};
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
